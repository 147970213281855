import React, { useState } from 'react';
import {
  Box, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader, Link,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,Tag,
  Table,
  Thead, Avatar,
  Tbody,
  Tr,
  Th, Card, Spinner,
  Td,
  Skeleton,
  VStack,
  Button,
  Text,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { PiCheckBold, PiXBold } from "react-icons/pi";

import { ChakraProvider } from '@chakra-ui/react';
import JobPostModal from '../../../components/post_job_modal';
import { Select, Empty } from 'antd';
import { FiCheck, FiFlag } from 'react-icons/fi';
import UserDetailsDrawer from '../../../components/ab_2534_drawer';
const { Option } = Select;

function Ab2534List() {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [expandedApplicationId, setExpandedApplicationId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const openDrawer = (id) => {
    setSelectedApplicationId(id);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedApplicationId(null);
  };
  // Hardcoded data with 5 applications
  const hardcodedApplications = [
    {
      id: 1,
      user: {
        first_name: 'John',
        last_name: 'Doe',
        outcome: 'pending',
        photo: 'https://via.placeholder.com/40',
        work_history: [
          {
            school_name: 'Greenwood Elementary School',
            hr_admin_name: 'Sarah Thompson',
            hr_admin_email: 'sarah.thompson@greenwood.edu',
            role_held: 'Kindergarten Teacher',
            employment_dates: 'August 2015 - June 2018',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'Unknown',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Maple Leaf Elementary School',
            hr_admin_name: 'Michael Lee',
            hr_admin_email: 'michael.lee@mapleleaf.edu',
            role_held: 'First Grade Teacher',
            employment_dates: 'August 2018 - June 2020',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Oakridge Elementary School',
            hr_admin_name: 'Linda Martinez',
            hr_admin_email: 'linda.martinez@oakridge.edu',
            role_held: 'Second Grade Teacher',
            employment_dates: 'August 2020 - Present',
            question1: 'Unknown',
            question2: 'Unknown',
            question3: 'Unknown',
            question4: 'Unknown',
            question5: 'Unknown',
            question6: 'Unknown',
          },
        ],
      },
      job: {
        title: 'Second Grade Teacher',
      },
      status: {
        completed: 3,
        total: 5,
      },
      isSelected: false,
      ai_match_score_reasoning: 'Good fit based on teaching experience and subject expertise.',
      slug: 'john-doe',
      source: 'LinkedIn',
    },
    {
      id: 2,
      user: {
        first_name: 'Jane',
        last_name: 'Smith',
        outcome: 'success',
        photo: 'https://via.placeholder.com/40',
        work_history: [
          {
            school_name: 'Sunnyvale Elementary School',
            hr_admin_name: 'Robert Brown',
            hr_admin_email: 'robert.brown@sunnyvale.edu',
            role_held: 'Third Grade Teacher',
            employment_dates: 'August 2014 - June 2016',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Cedar Grove Elementary School',
            hr_admin_name: 'Patricia Davis',
            hr_admin_email: 'patricia.davis@cedargrove.edu',
            role_held: 'Fourth Grade Teacher',
            employment_dates: 'August 2016 - June 2018',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Pinecrest Elementary School',
            hr_admin_name: 'James Wilson',
            hr_admin_email: 'james.wilson@pinecrest.edu',
            role_held: 'Fifth Grade Teacher',
            employment_dates: 'August 2018 - June 2020',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Riverdale Elementary School',
            hr_admin_name: 'Barbara Taylor',
            hr_admin_email: 'barbara.taylor@riverdale.edu',
            role_held: 'Sixth Grade Teacher',
            employment_dates: 'August 2020 - June 2022',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Lakeside Elementary School',
            hr_admin_name: 'William Anderson',
            hr_admin_email: 'william.anderson@lakeside.edu',
            role_held: 'Seventh Grade Teacher',
            employment_dates: 'August 2022 - Present',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Hillcrest Elementary School',
            hr_admin_name: 'Elizabeth Thomas',
            hr_admin_email: 'elizabeth.thomas@hillcrest.edu',
            role_held: 'Eighth Grade Teacher',
            employment_dates: 'August 2023 - Present',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
        ],
      },
      job: {
        title: 'Eighth Grade Teacher',
      },
      status: {
        completed: 6,
        total: 6,
      },
      isSelected: false,
      ai_match_score_reasoning: 'Excellent leadership and curriculum development skills.',
      slug: 'jane-smith',
      source: 'Indeed',
    },
    {
      id: 3,
      user: {
        first_name: 'Alice',
        last_name: 'Johnson',
        outcome: 'pending',
        photo: 'https://via.placeholder.com/40',
        work_history: [
          {
            school_name: 'Maplewood Elementary School',
            hr_admin_name: 'Kevin White',
            hr_admin_email: 'kevin.white@maplewood.edu',
            role_held: 'First Grade Teacher',
            employment_dates: 'August 2017 - June 2019',
            question1: 'Yes',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'Yes',
          },
          {
            school_name: 'Brookside Elementary School',
            hr_admin_name: 'Karen Harris',
            hr_admin_email: 'karen.harris@brookside.edu',
            role_held: 'Second Grade Teacher',
            employment_dates: 'August 2019 - June 2021',
            question1: 'No',
            question2: 'Unknown',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
        ],
      },
      job: {
        title: 'Second Grade Teacher',
      },
      status: {
        completed: 2,
        total: 4,
      },
      isSelected: false,
      ai_match_score_reasoning: 'Strong classroom management and student engagement skills.',
      slug: 'alice-johnson',
      source: 'Glassdoor',
    },
    {
      id: 4,
      user: {
        first_name: 'Bob',
        last_name: 'Williams',
        outcome: 'flag',
        photo: 'https://via.placeholder.com/40',
        work_history: [
          {
            school_name: 'Sunset Elementary School',
            hr_admin_name: 'Nancy Martin',
            hr_admin_email: 'nancy.martin@sunset.edu',
            role_held: 'Third Grade Teacher',
            employment_dates: 'August 2013 - June 2016',
            question1: 'Yes',
            question2: 'Yes',
            question3: 'No',
            question4: 'No',
            question5: 'Yes',
            question6: 'Yes',
          },
          {
            school_name: 'Cypress Elementary School',
            hr_admin_name: 'Thomas Garcia',
            hr_admin_email: 'thomas.garcia@cypress.edu',
            role_held: 'Fourth Grade Teacher',
            employment_dates: 'August 2016 - June 2019',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Willow Creek Elementary School',
            hr_admin_name: 'Donna Clark',
            hr_admin_email: 'donna.clark@willowcreek.edu',
            role_held: 'Fifth Grade Teacher',
            employment_dates: 'August 2019 - June 2021',
            question1: 'No',
            question2: 'No',
            question3: 'Yes',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Elmwood Elementary School',
            hr_admin_name: 'Steven Rodriguez',
            hr_admin_email: 'steven.rodriguez@elmwood.edu',
            role_held: 'Sixth Grade Teacher',
            employment_dates: 'August 2021 - June 2023',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'Yes',
            question5: 'No',
            question6: 'No',
          },
          {
            school_name: 'Pine Hill Elementary School',
            hr_admin_name: 'Laura Lewis',
            hr_admin_email: 'laura.lewis@pinehill.edu',
            role_held: 'Seventh Grade Teacher',
            employment_dates: 'August 2023 - Present',
            question1: 'No',
            question2: 'No',
            question3: 'No',
            question4: 'No',
            question5: 'No',
            question6: 'No',
          },
        ],
      },
      job: {
        title: 'Seventh Grade Teacher',
      },
      status: {
        completed: 5,
        total: 5,
      },
      isSelected: false,
      ai_match_score_reasoning: 'Proven track record in developing effective teaching strategies.',
      slug: 'bob-williams',
      source: 'Referral',
    },
    {
      id: 5,
      user: {
        first_name: 'Emily',
        last_name: 'Brown',
        outcome: 'pending',
        photo: 'https://via.placeholder.com/40',
        work_history: [
          {
            school_name: 'Cedar Park Elementary School',
            hr_admin_name: 'Richard Walker',
            hr_admin_email: 'richard.walker@cedarpark.edu',
            role_held: 'Kindergarten Teacher',
            employment_dates: 'August 2020 - June 2021',
            question1: 'Unknown',
            question2: 'Unknown',
            question3: 'Unknown',
            question4: 'Unknown',
            question5: 'Unknown',
            question6: 'Unknown',
          },
        ],
      },
      job: {
        title: 'Kindergarten Teacher',
      },
      status: {
        completed: 1,
        total: 3,
      },
      isSelected: false,
      ai_match_score_reasoning: 'Creative design skills and user-centric approach in the classroom.',
      slug: 'emily-brown',
      source: 'Career Fair',
    },
  ];
  

  const [applications, setApplications] = useState(hardcodedApplications);
  const [jobs] = useState([
    { id: 1, title: 'Kindergarten Teacher' },
    { id: 2, title: 'Second Grade Teacher' },
    { id: 3, title: 'Seventh Grade Teacher' },
    { id: 4, title: 'Eighth Grade Teacher' },
  ]);
  const [schoolName] = useState('Example School');
  const [schoolId] = useState('school123');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [roleFilter, setRoleFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('All Outcomes');
  const [matchScoreFilter, setMatchScoreFilter] = useState('all');

  // Function to append a new job to the list
  const addNewJobToList = (newJob) => {
    setApplications((prevapplications) => [...prevapplications, newJob]);
  };
  
  const toggleDetails = (id) => {
    setExpandedApplicationId(prevId => prevId === id ? null : id);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedApplications = applications.map(job => ({
      ...job,
      isSelected: newSelectAll
    }));
    setApplications(updatedApplications);
  };

  const handleCheckboxChange = (id) => {
    setApplications(applications.map(job => {
      if (job.id === id) {
        return { ...job, isSelected: !job.isSelected };
      }
      return job;
    }));
  };

  const isAnyApplicationSelected = () => {
    return applications.some(job => job.isSelected);
  };



  return (
    <ChakraProvider>
      <Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'} position="relative" bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}>
        <Box py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
          <Card px={'30px'} py={'30px'} boxShadow="xl">
            <Box position={'sticky'}>
              <Flex justifyContent={'space-between'}>
                <Text fontSize="xl" fontWeight="bold">AB 2534 Referrence Checks</Text>
              </Flex>

              <Flex width={'100%'} py={4} align="center">
                <Box width="30%" mr={4}>
                  <Select
                    defaultValue={roleFilter}
                    style={{ width: '100%' }}
                    placeholder="Filter by role"
                    onChange={(value) => setRoleFilter(value)}
                  >
                    <Option value="all">All Roles</Option>
                    {jobs.map(job => (
                      <Option key={job.id} value={job.id}>{job.title}</Option>
                    ))}
                  </Select>
                </Box>

                <Box width="20%" mr={4}>
                  <Select
                    defaultValue={statusFilter}
                    style={{ width: '100%' }}
                    placeholder="Filter by status"
                    onChange={(value) => setStatusFilter(value)}
                  >
                    <Option value="all">All Outcomes</Option>
                    <Option value="Pending">Pending</Option>
                    <Option value="Accepted">Cleared</Option>
                    <Option value="Rejected">Issue Flagged</Option>
                  </Select>
                </Box>

       
              </Flex>
            </Box>

            {applications && applications.length > 0 ? (
              <Box overflowY="scroll">
                <Table variant="simple">
                  <Thead bg="gray.200" position="sticky" top={0} zIndex={1}>
                    <Tr>
                      <Th textTransform="uppercase" minWidth="260px" fontSize="xs" fontWeight="bold" color="gray.500">Candidate</Th>
                      <Th textTransform="uppercase" minWidth="200px" fontSize="xs" fontWeight="bold" color="gray.500">Role Applied To</Th>
                      <Th textTransform="uppercase" textAlign="center" fontSize="xs" fontWeight="bold" color="gray.500">Completion Status</Th>
                      <Th textTransform="uppercase" textAlign="center" fontSize="xs" fontWeight="bold" color="gray.500">Outcome</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {applications.map((application) => (
                      <React.Fragment key={`fragment-${application.id}`}>
                        <Tr height="50px" borderWidth="0px">
                          <Td as={Link} display="flex" borderWidth="0px" onClick={() => openDrawer(application.id)}>
                            <Avatar height="40px" width="40px" marginRight="10px" src={application.user.photo} />
                            <Box>
                              <Flex>
                                <Text
                                  fontWeight="bold"
                                  onClick={() => openDrawer(
                                    application.user.id,
                                    application.id,
                                    application.ai_match_score_reasoning,
                                    application.job.title,
                                    application.status,
                                    application.source,
                                    application.slug
                                  )}
                                  cursor="pointer"
                                >
                                  {application.user.first_name} {application.user.last_name}
                                </Text>
                              </Flex>
                              {application.user.work_history && application.user.work_history.length > 0 && (
                                <Text fontSize="sm" color="gray.600">
                                  {application.user.work_history[0].role_held}, {application.user.work_history[0].school_name}
                                </Text>
                              )}
                            </Box>
                          </Td>
                          <Td borderWidth="0px">
                            <Text fontSize="sm">
                              {application.job.title}
                            </Text>
                          </Td>
                          <Td borderWidth="0px" fontSize="sm" textAlign="center">
                            <Flex direction="column" align="center">
                              <CircularProgress
                                value={(application.status.completed / application.status.total) * 100}
                                size="50px"
                                color={application.status.completed === application.status.total ? "green.400" : "blue.400"}
                              >
                                <CircularProgressLabel>{application.status.completed}/{application.status.total}</CircularProgressLabel>
                              </CircularProgress>
                            </Flex>
                          </Td>
                          <Td borderWidth="0px" fontSize="sm" textAlign="center">
                            <Flex direction="column" align="center" justify="center">
                              {application.user.outcome === 'success' && (

                                <Tag variant='subtle' w={'180px'} textAlign={'center'} justifyContent={'center'} colorScheme='green' style={{display:'flex', alignItems: 'center', fontFamily:'inherit'}}>
                                <FiCheck size={16}  />
                                <Text ml={2}>Cleared</Text>
                                </Tag>
                              )}
                              {application.user.outcome === 'flag' && (
                                 <Tag w={'180px'} justifyContent={'center'} variant='subtle' colorScheme='red' style={{display:'flex', alignItems: 'center', fontFamily:'inherit'}}>
                                <FiFlag  size={16} />
                                <Text ml={2}>Issue Flagged</Text>
                                </Tag>
                              )}
                            </Flex>
                          </Td>
                        </Tr>

    
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
                <UserDetailsDrawer
                    isOpen={isDrawerOpen}
                    onClose={closeDrawer}
                    applicationId={selectedApplicationId}
                />
              </Box>
            ) : (
              <Box textAlign="center" fontSize="xl" mt={5}>
                <Empty
                  description={
                    statusFilter === "Pending" ? "No pending applications" :
                    statusFilter === "Accepted" ? "No accepted applications" :
                    statusFilter === "Rejected" ? "No rejected applications" :
                    "No applications"
                  }
                />
              </Box>
            )}
          </Card>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Ab2534List;
