// UserDetailsDrawer.js
import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box, Tag,
  Flex,
  Avatar,
  Heading,
  Text,
  Badge,
  Divider,
  Stack,
  SimpleGrid,
  Link,
  VStack,
} from '@chakra-ui/react';
import { FiCheck, FiClock, FiFlag } from 'react-icons/fi';

// Hardcoded Applications Data
const hardcodedApplications = [
  {
    id: 1,
    user: {
      first_name: 'John',
      last_name: 'Doe',
      outcome: 'pending',
      photo: 'https://via.placeholder.com/40',
      work_history: [
        {
          school_name: 'Greenwood Elementary School',
          hr_admin_name: 'Sarah Thompson',
          hr_admin_email: 'sarah.thompson@greenwood.edu',
          role_held: 'Kindergarten Teacher',
          employment_dates: 'August 2015 - June 2018',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'Unknown',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Maple Leaf Elementary School',
          hr_admin_name: 'Michael Lee',
          hr_admin_email: 'michael.lee@mapleleaf.edu',
          role_held: 'First Grade Teacher',
          employment_dates: 'August 2018 - June 2020',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Oakridge Elementary School',
          hr_admin_name: 'Linda Martinez',
          hr_admin_email: 'linda.martinez@oakridge.edu',
          role_held: 'Second Grade Teacher',
          employment_dates: 'August 2020 - Present',
          question1: 'Unknown',
          question2: 'Unknown',
          question3: 'Unknown',
          question4: 'Unknown',
          question5: 'Unknown',
          question6: 'Unknown',
        },
      ],
    },
    job: {
      title: 'Second Grade Teacher',
    },
    status: {
      completed: 3,
      total: 5,
    },
    isSelected: false,
    ai_match_score_reasoning: 'Good fit based on teaching experience and subject expertise.',
    slug: 'john-doe',
    source: 'LinkedIn',
  },
  {
    id: 2,
    user: {
      first_name: 'Jane',
      last_name: 'Smith',
      outcome: 'success',
      photo: 'https://via.placeholder.com/40',
      work_history: [
        {
          school_name: 'Sunnyvale Elementary School',
          hr_admin_name: 'Robert Brown',
          hr_admin_email: 'robert.brown@sunnyvale.edu',
          role_held: 'Third Grade Teacher',
          employment_dates: 'August 2014 - June 2016',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Cedar Grove Elementary School',
          hr_admin_name: 'Patricia Davis',
          hr_admin_email: 'patricia.davis@cedargrove.edu',
          role_held: 'Fourth Grade Teacher',
          employment_dates: 'August 2016 - June 2018',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Pinecrest Elementary School',
          hr_admin_name: 'James Wilson',
          hr_admin_email: 'james.wilson@pinecrest.edu',
          role_held: 'Fifth Grade Teacher',
          employment_dates: 'August 2018 - June 2020',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Riverdale Elementary School',
          hr_admin_name: 'Barbara Taylor',
          hr_admin_email: 'barbara.taylor@riverdale.edu',
          role_held: 'Sixth Grade Teacher',
          employment_dates: 'August 2020 - June 2022',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Lakeside Elementary School',
          hr_admin_name: 'William Anderson',
          hr_admin_email: 'william.anderson@lakeside.edu',
          role_held: 'Seventh Grade Teacher',
          employment_dates: 'August 2022 - Present',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Hillcrest Elementary School',
          hr_admin_name: 'Elizabeth Thomas',
          hr_admin_email: 'elizabeth.thomas@hillcrest.edu',
          role_held: 'Eighth Grade Teacher',
          employment_dates: 'August 2023 - Present',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
      ],
    },
    job: {
      title: 'Eighth Grade Teacher',
    },
    status: {
      completed: 6,
      total: 6,
    },
    isSelected: false,
    ai_match_score_reasoning: 'Excellent leadership and curriculum development skills.',
    slug: 'jane-smith',
    source: 'Indeed',
  },
  {
    id: 3,
    user: {
      first_name: 'Alice',
      last_name: 'Johnson',
      outcome: 'pending',
      photo: 'https://via.placeholder.com/40',
      work_history: [
        {
          school_name: 'Maplewood Elementary School',
          hr_admin_name: 'Kevin White',
          hr_admin_email: 'kevin.white@maplewood.edu',
          role_held: 'First Grade Teacher',
          employment_dates: 'August 2017 - June 2019',
          question1: 'Yes',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'Yes',
        },
        {
          school_name: 'Brookside Elementary School',
          hr_admin_name: 'Karen Harris',
          hr_admin_email: 'karen.harris@brookside.edu',
          role_held: 'Second Grade Teacher',
          employment_dates: 'August 2019 - June 2021',
          question1: 'No',
          question2: 'Unknown',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
      ],
    },
    job: {
      title: 'Second Grade Teacher',
    },
    status: {
      completed: 2,
      total: 4,
    },
    isSelected: false,
    ai_match_score_reasoning: 'Strong classroom management and student engagement skills.',
    slug: 'alice-johnson',
    source: 'Glassdoor',
  },
  {
    id: 4,
    user: {
      first_name: 'Bob',
      last_name: 'Williams',
      outcome: 'flag',
      photo: 'https://via.placeholder.com/40',
      work_history: [
        {
          school_name: 'Sunset Elementary School',
          hr_admin_name: 'Nancy Martin',
          hr_admin_email: 'nancy.martin@sunset.edu',
          role_held: 'Third Grade Teacher',
          employment_dates: 'August 2013 - June 2016',
          question1: 'Yes',
          question2: 'Yes',
          question3: 'No',
          question4: 'No',
          question5: 'Yes',
          question6: 'Yes',
        },
        {
          school_name: 'Cypress Elementary School',
          hr_admin_name: 'Thomas Garcia',
          hr_admin_email: 'thomas.garcia@cypress.edu',
          role_held: 'Fourth Grade Teacher',
          employment_dates: 'August 2016 - June 2019',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Willow Creek Elementary School',
          hr_admin_name: 'Donna Clark',
          hr_admin_email: 'donna.clark@willowcreek.edu',
          role_held: 'Fifth Grade Teacher',
          employment_dates: 'August 2019 - June 2021',
          question1: 'No',
          question2: 'No',
          question3: 'Yes',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Elmwood Elementary School',
          hr_admin_name: 'Steven Rodriguez',
          hr_admin_email: 'steven.rodriguez@elmwood.edu',
          role_held: 'Sixth Grade Teacher',
          employment_dates: 'August 2021 - June 2023',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'Yes',
          question5: 'No',
          question6: 'No',
        },
        {
          school_name: 'Pine Hill Elementary School',
          hr_admin_name: 'Laura Lewis',
          hr_admin_email: 'laura.lewis@pinehill.edu',
          role_held: 'Seventh Grade Teacher',
          employment_dates: 'August 2023 - Present',
          question1: 'No',
          question2: 'No',
          question3: 'No',
          question4: 'No',
          question5: 'No',
          question6: 'No',
        },
      ],
    },
    job: {
      title: 'Seventh Grade Teacher',
    },
    status: {
      completed: 5,
      total: 5,
    },
    isSelected: false,
    ai_match_score_reasoning: 'Proven track record in developing effective teaching strategies.',
    slug: 'bob-williams',
    source: 'Referral',
  },
  {
    id: 5,
    user: {
      first_name: 'Emily',
      last_name: 'Brown',
      outcome: 'pending',
      photo: 'https://via.placeholder.com/40',
      work_history: [
        {
          school_name: 'Cedar Park Elementary School',
          hr_admin_name: 'Richard Walker',
          hr_admin_email: 'richard.walker@cedarpark.edu',
          role_held: 'Kindergarten Teacher',
          employment_dates: 'August 2020 - June 2021',
          question1: 'Unknown',
          question2: 'Unknown',
          question3: 'Unknown',
          question4: 'Unknown',
          question5: 'Unknown',
          question6: 'Unknown',
        },
      ],
    },
    job: {
      title: 'Kindergarten Teacher',
    },
    status: {
      completed: 1,
      total: 3,
    },
    isSelected: false,
    ai_match_score_reasoning: 'Creative design skills and user-centric approach in the classroom.',
    slug: 'emily-brown',
    source: 'Career Fair',
  },
];
const questions = [
    {
      id: 1,
      text: 'Was the applicant ever the subject of any credible complaint of, substantiated investigations into, or discipline for egregious misconduct during their employment at your school/district?',
    },
    {
      id: 2,
      text: 'Specifically, were there any credible complaints of, substantiated investigations into, or discipline related to the following: Sexual offenses?',
    },
    {
      id: 3,
      text: 'Child abuse or neglect?',
    },
    {
      id: 4,
      text: 'Involvement with controlled substances?',
    },
    {
      id: 5,
      text: 'Was this applicant dismissed or asked to resign from their former/current position?',
    },
    {
      id: 6,
      text: 'Were any incidents involving the applicant reported to the California Commission on Teacher Credentialing (CTC)? If yes, please explain.',
    },
  ];

const UserDetailsDrawer = ({ isOpen, onClose, applicationId }) => {
  // Find the application based on applicationId
  const application = hardcodedApplications.find(app => app.id === applicationId);

  // If application is not found, render nothing or a fallback UI
  if (!application) {
    return null;
  }

  const { user, job, ai_match_score_reasoning } = application;

  // Determine badge color based on outcome
  const getOutcomeBadgeColor = (outcome) => {
    switch (outcome) {
      case 'success':
        return 'green';
      case 'flag':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent style={{overflow:'scroll'}}>
        <DrawerCloseButton />

        <Box p={6}>
          {/* Applicant Information */}
          <Flex alignItems="center" mb={4}>
            <Avatar size="lg" src={user.photo} mr={4} />
            <Box>
              <Heading size="md">
                {user.first_name} {user.last_name}
              </Heading>
              {application.user.outcome === 'success' && (

<Tag  p={1} mt={2} variant='subtle'  textAlign={'center'} justifyContent={'center'} colorScheme='green' style={{display:'flex', alignItems: 'center', fontFamily:'inherit'}}>
<FiCheck size={16}  />
<Text ml={2}>Cleared</Text>
</Tag>
)}
{application.user.outcome === 'flag' && (
 <Tag p={1} mt={2} justifyContent={'center'} variant='subtle' colorScheme='red' style={{display:'flex', alignItems: 'center', fontFamily:'inherit'}}>
<FiFlag  size={16} />
<Text ml={2}>Issue Flagged</Text>
</Tag>
)}
{application.user.outcome === 'pending' && (
 <Tag p={1} mt={2} justifyContent={'center'} variant='subtle'  style={{display:'flex', alignItems: 'center', fontFamily:'inherit'}}>
<FiClock  size={16} />
<Text ml={2}>Pending</Text>
</Tag>
)}
            </Box>
          </Flex>

          <Divider mb={4} />

          {/* Job Applied */}
          {/* <Box mb={4}>
            <Heading size="sm" mb={2}>
              Job Applied
            </Heading>
            <Text>{job.title}</Text>
          </Box> */}

          {/* Work History */}
          <Box>
            <Heading size="sm" mb={2}>
              Work History
            </Heading>
            <Stack spacing={4}>
              {user.work_history.map((work, index) => (
                <Box key={index} p={4} borderWidth="1px" borderRadius="md">
                  <Flex justifyContent="space-between" mb={2}>
                    <Text fontWeight="bold">
                      {work.role_held} at {work.school_name}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      {work.employment_dates}
                    </Text>
                  </Flex>
                  <Text>
                    <strong>HR Admin:</strong> {work.hr_admin_name}, <Link> {work.hr_admin_email}</Link>
                  </Text>
     
                  <Box mt={2}>
                    {/* <Text fontWeight="bold">AB 2534 Referrence Form Response:</Text> */}
                    <VStack align="start" spacing={1} mt={1}>
                      {questions.map(q => (
                        <Text mb={2} key={q.id}>
                          {q.id}) {q.text}
                          <Text
                          ml={2}
                        as="span"
                        fontWeight="bold"
                        color={['Yes', 'Unknown'].includes(work[`question${q.id}`]) ? 'red.500' : 'black'}
                        >
                        {work[`question${q.id}`]}
                        </Text>

                        </Text>
                      ))}
                    </VStack>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Box>

          {application.status.total - application.status.completed > 0 && (
  <Box mt={8} w="100%">
    <hr/>
    <Heading size="sm" mb={2} mt={8}>
      Still Pending
    </Heading>
    <Stack spacing={3}>

  {/* Pending Reference 3 */}
  <Box p={3} borderWidth="1px" borderRadius="md" w="100%">
    <Text>
      <strong>School Name:</strong> Greenwood Elementary School
    </Text>
    <Text>
      <strong>Date of Last Contact:</strong> January 12th, 2025
    </Text>
    <Text>
      <strong>Person Contacted:</strong> Sarah Williams
    </Text>
  </Box>

  {/* Add additional pending references if needed */}
  <Box p={3} borderWidth="1px" borderRadius="md" w="100%">
    <Text>
      <strong>School Name:</strong> Oakridge Middle School
    </Text>
    <Text>
      <strong>Date of Last Contact:</strong> January 10, 2025
    </Text>
    <Text>
      <strong>Person Contacted:</strong> David Brown
    </Text>
  </Box>
</Stack>

  </Box>
)}

        </Box>

        

        
      </DrawerContent>
    </Drawer>
  );
};

export default UserDetailsDrawer;
