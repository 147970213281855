import {
    Box, Modal, ModalBody, ModalOverlay, ModalFooter, ModalContent,ModalHeader, ModalCloseButton,
    Button, Popover, PopoverTrigger, PopoverCloseButton, PopoverContent, PopoverBody,PopoverArrow, PopoverHeader,
    Container,Spinner, Image,  Accordion,
    AccordionItem, Alert,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Heading, Card, Skeleton, SkeletonText,
    Select, Flex,
    Stack, Img, Grid, GridItem,
    Text,
    VStack, Checkbox,
    HStack,
    Divider,
    useDisclosure
  } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiBriefcase, FiMapPin, FiFolder, FiSlash, FiInfo, FiAlertTriangle } from "react-icons/fi";
import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";

import UploadResume from '../job_search_onboarding/1_upload_resume_screen';
import { ApplyWithResume } from '../../../components/apply_with_resume';
import ImageUploading from 'react-images-uploading';
import ConfirmProfile from '../job_search_onboarding/2_confirm_profile';
import EditProfileForm from '../../../components/edit_profile_form';


  function JobPage() {



    
    const { isOpen, onOpen, onClose } = useDisclosure()

        // Only map over photos if it's defined and not empty
        const maxNumber = 69;

    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
  
    const navigate = useNavigate();
  
    const inputFileRef = React.useRef(null);
  
    const [images, setImages] = React.useState([]);

    const handleImagesChange = (updatedImages) => {
      setImages(updatedImages);
  };
  
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    handleImagesChange(imageList); // Call the callback function with the updated images
};
  const eventData = {
      images,
    };
  
  
    const handleResumeUpload = async () => {
        try {
          setIsLoading(true); // Start loading
          setLoadingText('Updating your profile ...')
          // Define base URL
          const baseUrl = 'https://www.pursueli.com/api';
          
          // Initial data for the first POST request
          const eventData = {
            images,
          };      
          // First request to OnboardingScreenOne
          let response = await fetch(`${baseUrl}/upload_resume/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(eventData)
          });
      
          if (!response.ok) throw new Error(`Error: ${response.status}`);
          setLoadingText('')
          setLoadingText('Updating your work history ...')
          let data = await response.json();
          let text = data.text;
      
          // Use 'text' from the first response in the second request
          response = await fetch(`${baseUrl}/ai_update_work_exp/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }) // Pass 'text' for processing
          });
      
          if (!response.ok) throw new Error(`Error: ${response.status}`);
          data = await response.json();
          text = data.text; // Update 'text' if necessary
      
          setLoadingText('')
          setLoadingText('Updating your education & credentials ...')
          // Use 'text' from the second response in the third request
          response = await fetch(`${baseUrl}/ai_update_edu_exp/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }) // Pass 'text' for final processing
          });
      
          if (!response.ok) throw new Error(`Error: ${response.status}`);
      
          // Finalize the process
          setIsLoading(false); // Stop loading
          navigate('/confirm_profile'); // Navigate after successful processing
          console.log("success");
      
        } catch (error) {
          console.error("Failed to process the data:", error);
          setIsLoading(false); // Ensure loading is stopped in case of an error
        }
      };

    const handleSubmit = async () => {
        onOpen()
        return
      try {
        setIsLoading(true); // Start loading
        setLoadingText('Updating your profile ...')
        // Define base URL
        const baseUrl = 'https://www.pursueli.com/api';
        
        // Initial data for the first POST request
        const eventData = {
          images,
        };      
        // First request to OnboardingScreenOne
        let response = await fetch(`${baseUrl}/upload_resume/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(eventData)
        });
    
        if (!response.ok) throw new Error(`Error: ${response.status}`);
        setLoadingText('')
        setLoadingText('Updating your work history ...')
        let data = await response.json();
        let text = data.text;
    
        // Use 'text' from the first response in the second request
        response = await fetch(`${baseUrl}/ai_update_work_exp/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text }) // Pass 'text' for processing
        });
    
        if (!response.ok) throw new Error(`Error: ${response.status}`);
        data = await response.json();
        text = data.text; // Update 'text' if necessary
    
        setLoadingText('')
        setLoadingText('Updating your education & credentials ...')
        // Use 'text' from the second response in the third request
        response = await fetch(`${baseUrl}/ai_update_edu_exp/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text }) // Pass 'text' for final processing
        });
    
        if (!response.ok) throw new Error(`Error: ${response.status}`);
    
        // Finalize the process
        setIsLoading(false); // Stop loading
        navigate('/confirm_profile'); // Navigate after successful processing
        console.log("success");
    
      } catch (error) {
        console.error("Failed to process the data:", error);
        setIsLoading(false); // Ensure loading is stopped in case of an error
      }
    };


    let { slug } = useParams();
    const [job, setJob] = useState({})
    const [schoolLogo, setSchoolLogo] = useState({})
    const [groupedJobs, setGroupedJobs] = useState({});

    const [source, setSource] = useState('');

    useEffect(() => {    
      const searchParams = new URLSearchParams(window.location.search);
      const sourceParam = searchParams.get('source');
      setSource(sourceParam);
      // Assuming 'school' is the query parameter expected by your backend
      // and 'school' state contains the current selected school value.
      fetch(`https://www.edpursuit.com/api/school_public_route_get_job/?job=${slug}&&source=${sourceParam}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        const jobData = data.job;
        setJob(jobData); // If you still need to setJobs separately
        setSchoolLogo(data.logo); // If you still need to setJobs separately
      })
      .catch(error => console.error("Error fetching jobs:", error));
    }, []);
    
    
    if (!job.school_sites || job.school_sites.length === 0) {
        return  <Box>
        <Container bg="white" color="black" p={1} position="sticky" top={0} zIndex={1} boxShadow="md" minW={'100%'} py={2}>
          
          <Box mx={'auto'} maxW={{ base: '100%', md: '75%', lg: '70%' }}>
          <Flex  
            py={2} alignItems="center"  >
            <Box
              alt="School Logo"
              h="45px"
            />
            {/* Add more elements here if needed */}
          </Flex>
          </Box>
        </Container>
<Box >
 <Container  maxW={{ base: '100%', md: '75%', lg: '75%' }} textAlign={'start'} py={5}>
  
            <Box flexDirection={{ base: 'column', md: 'row', lg: 'row' }}>
                <Box mt={5} display={'flex'}>
                  <Box w={{ base: '100%', md: '60%', lg: '65%' }}  px={{ base: '0', md:5, lg: 8 }}>
                        <Skeleton height="20px" mb="4"/>
                        <SkeletonText  mt="4" noOfLines={4} spacing="4"  />
                        <br></br>
                        <br></br>
                        <br></br>
                        <Skeleton height="20px" mb="4"/>
                        <SkeletonText mt="4" noOfLines={4} spacing="4"  />
                        <br></br>
                        <br></br>
                        <br></br>
                        <Skeleton height="20px" mb="4"/>
                        <SkeletonText mt="4" noOfLines={4} spacing="4"  />
                  </Box>
                  <Box pl={5} w={{ base: '100%', md: '40%', lg: '35%' }}>
                    <Card w={'100%'} p={5}>
                    <Skeleton height="20px" mb="4"/>
                        <SkeletonText mt="4" noOfLines={4} spacing="4"  />
                        <br></br>
                        <br></br>
                  <Box display="flex" justifyContent="center">
                    <Button colorScheme="teal" variant="outline">
                    <SkeletonText mt="0" noOfLines={1} width={'100px'}  />
                    </Button>
                  </Box>                    
                  </Card>
                  </Box>
                  </Box>

                  </Box>
                  </Container>
</Box>
              </Box>
    }

    return (
        <Box>
               <Container
        bg="white"
        color="black"
        p={1}
        position="sticky"
        top={0}
        zIndex={1}
        boxShadow="md"
        minW={'100%'}
        py={2}

      
      >
        <Box mx={'auto'} maxW={{ base: '100%', md: '75%', lg: '70%' }}>
        <Flex  
         py={2} alignItems="center"  >
          <Img
            src={schoolLogo} // Replace with your logo path
            alt="School Logo"
            h="45px"
          />
          {/* Add more elements here if needed */}
        </Flex>
        </Box>
      </Container>
      <Box   marginLeft={{sm:0, md:'15%'}}>
        <Container  maxW={{ base: '100%', md: '75%', lg: '100%' }} textAlign={'start'} py={5}>
        <Grid  templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }} gap={6}>
          <GridItem colSpan={{ sm: 1, md: 6 }}>

            <Box marginTop="5px"></Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} marginTop={5} alignItems={'center'}>
                <Box  alignSelf={'center'} >
                <Heading  as="h2" size="lg"  /* other style props */>
                {job.title}
                </Heading>                    
                </Box>
              </Box>
            <style>
                {`
                    .hide-scrollbar::-webkit-scrollbar {
                        display: none;
                    }
                    .hide-scrollbar {
                        -ms-overflow-style: none; /* IE and Edge */
                        scrollbar-width: none; /* Firefox */
                    }
                `}
            </style>
            <Heading  as="h2" size="md" mt={10}  /* other style props */>
                Summary
            </Heading> 
            <Box mt="2" style={{ whiteSpace: 'pre-wrap', paddingTop:'15px' }}>
                {job.description}
            </Box>

            {job.requirements && (
                <>
              <Heading  as="h2" size="md" mt={10} /* other style props */>
                Responsibilities
              </Heading> 
              <Box>
              <div style={{padding:'20px'}} dangerouslySetInnerHTML={{ __html: job.requirements }}></div>
              </Box>
                </>
              )}
              {job.qualifications && (
                  <>
                    <Heading as="h2" size="md" mt={10} /* other style props */>
                      Qualifications
                    </Heading>
                    <Box>
                      <div style={{padding:'20px'}} dangerouslySetInnerHTML={{ __html: job.qualifications }}></div>
                    </Box>
                  </>
                )}

              </GridItem>
          <GridItem pl={{sm:0, md:5}} colSpan={{ sm: 1, md: 6 }}  >
          <Box marginTop="5px" marginRight={{ sm: 0, md:'15%'}} position={{ sm: 'initial', md: 'fixed' }}  >

            <Box  mt={5}  borderWidth="1px" borderRadius="lg" p={4} shadow="md">
              <Heading as="h4" size="md">Apply</Heading>
              <Text mt={3} fontSize={'md'} color={'grey'} >If you already have a edpursuit account, log in to link this application to your account and pre-fill your information.</Text>


              <Box mt={3} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              {!localStorage.getItem('authToken') && (
              <Button marginRight={3} variant={'outline'}>
                Login
              </Button>
              )}
              <Button  onClick={handleSubmit} colorScheme='blue' >
                Apply
              </Button>
              </Box>








      </Box>
            </Box>
          </GridItem>
        </Grid>
              
              
        </Container>
        </Box>
        <Modal  isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent  maxW={{ base: '100vh', md: '55vw', lg: '55vw' }} >
          <ModalHeader fontSize={'1.75rem'} >Application Details

          <Text fontWeight={'normal'} fontSize={'sm'}>Complete the form below to apply for this role.</Text>

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

                    <EditProfileForm job={job} onClose={onClose}/>

          </ModalBody>
        </ModalContent>
      </Modal>
      </Box>
    );
  }
  
  export default JobPage;
  