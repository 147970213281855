import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Navbar from './components/nav_bar'; // Import the Navbar component
import Login from './screens/saas/login';
import Outivities from './screens/app/outivities';
import Footer from './components/footer';
import SignUp from './screens/saas/register';
import UploadResume from './screens/saas/job_search_onboarding/1_upload_resume_screen';
import ConfirmProfile from './screens/saas/job_search_onboarding/2_confirm_profile';
import ConfirmPreferences from './screens/saas/job_search_onboarding/3_job_search_preferences';
import SchoolDashboardJobs from './screens/saas/school_admin/jobs';
import SideNavBar from './components/side_nav_bar';
import { Flex } from 'antd';
import { SchoolProvider } from './context/admin_selected_school';
import SchoolDashboardApplications from './screens/saas/school_admin/applications';
import { Box, Text } from '@chakra-ui/react';
import Home from './screens/app/job_searcher/home';
import AdminNavbar from './components/admin_nav_bar';
import TeamSettings from './screens/saas/school_admin/settings';
import AdminInvite from './screens/saas/school_admin/invite';
import JobListingsPage from './screens/saas/school_admin_public/job_list';
import JobPage from './screens/saas/school_admin_public/job';
import ConfirmProfileAffterCareerPageApplication from './screens/saas/school_admin_public/new_user_onboarding';
import EditProfileFormAfterApplying from './components/edi_profile_logged_in';
import OrgJobsListingPage from './screens/saas/school_admin_public/org_job_list';
import OrgCareersProfile from './screens/saas/school_admin_public/career_profile';
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from '@sentry/react';
import RecruiterLogin from './screens/saas/school_admin/recruiter_login';
import AiInterview from './screens/saas/talent_user/ai_interview';
import StandAloneApplication from './screens/saas/school_admin/stand_alone_application';
import CandidatePipeline from './screens/saas/school_admin/pipeline';
import PublicJobsCCSA from './screens/app/public_jobs_ccsa';
import AdminReports from './screens/saas/school_admin/reports';
import CCSASideNavBar from './components/ccsa_side_nav_bar';
import Schools from './screens/ccsa/schools';
import Community from './screens/ccsa/community';
import CCSATopNav from './components/ccsa_top_nav';
import Jobs from './screens/app/jobs';
import SideNavBar202407 from './components/side_nav_2024_7';
import Source from './screens/saas/school_admin/source';
import AdminTalentCollective from './screens/saas/school_admin/talent_collective';
import TalentNetworkReferralPageAdmin from './screens/saas/school_admin/talent_network_referral_page';
import TalentCollectiveSetPassord from './screens/saas/school_admin/talent_collective_referral_sign_up_set_password';
import Ab2534List from './screens/saas/school_admin/ab_2534_list';


Sentry.init({
  dsn: "https://a5a9645ac5544e220e820df5f0aa8436@o4507139797417984.ingest.us.sentry.io/4507139840147456",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^http:\/\/localhost:3000\/?/, // Matches 'http://localhost:3000' with or without a trailing slash
    /^https?:\/\/www\.edpursuit\.com\/(api\/?)?$/, // Matches 'http://www.edpursuit.com', 'http://www.edpursuit.com/api', with or without trailing slash, both http and https
    /^https?:\/\/www\.pursueli\.com\/(api\/?)?$/, // Matches 'http://www.pursueli.com', 'http://www.pursueli.com/api', with or without trailing slash, both http and https
  ],
    // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const Navigation = () => {
    const location = useLocation();
    const adminRoutes = [
      '/admin_school_jobs', 
      '/admin_reports',
      '/admin_school_applications', 
      '/admin_team_settings', 
      '/admin_invite',
      '/admin/application/:slug', // Corrected to include this route
      '/admin_application/:slug',
      '/admin_candidate_pipeline',
      '/admin_talent_collective',
      '/admin_ab_2534_list'
    ];

    const hideNavBarRoutes = [
      '/jobs/:slug',
      '/job/:slug/',
      '/jobs/organization/:slug',
      '/jobs/career-profile/:slug',
    ];

    const hideCCSARoutes = [
      '/ccsa_staff/schools',
      '/ccsa_staff/community',
      '/ccsa',
      '/source',
      '/admin_talent_collective'
    ];


    // Checks if the current route matches any admin route patterns
    const isAdminRoute = () => {
      return adminRoutes.some((route) => {
        // Transform route patterns to match dynamic paths
        const pattern = route.replace(/:\w+/g, '[^/]+'); // Matches any characters except '/'
        return new RegExp(`^${pattern}$`).test(location.pathname);
      });
    };

    const shouldHideNavBar = () => {
      return hideNavBarRoutes.some((route) => {
        const pattern = route.replace(/:\w+/g, '[^/]+');
        return new RegExp(`^${pattern}$`).test(location.pathname);
      });
    };

    const shouldHideNavBarCCSA = () => {
      return hideCCSARoutes.some((route) => {
        const pattern = route.replace(/:\w+/g, '[^/]+');
        return new RegExp(`^${pattern}$`).test(location.pathname);
      });
    };

    // Decide which Navbar to render based on the route
    if (isAdminRoute() || shouldHideNavBar() || shouldHideNavBarCCSA()) {
      return null; // Hide Navbar for admin and specific routes
    } else {
      return <Navbar />; // Render the standard Navbar for non-admin routes
    }
  };

  return (
    <SchoolProvider>
      <ErrorBoundary fallback={"An error has occurred"}>
        <Router basename='/'>
          <div className="App">
            {/* Dynamic Navigation Based on Route */}
            <Routes>
              {/* Insert a Route at the top level to always render Navigation */}
              <Route path="*" element={<Navigation />} />
            </Routes>

            <Routes>
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/recruiter_login" element={<RecruiterLogin />} />
              <Route path="/upload_resume" element={<UploadResume />} />
              <Route path="/confirm_profile" element={<ConfirmProfile />} />
              <Route path="/confirm_preferences" element={<ConfirmPreferences />} />
              <Route path="/chat" element={<AiInterview />} />
              <Route path="/home" element={<Home />} />
              <Route path="/admin_invite" element={<AdminInvite />} />
              <Route path="/jobs/:slug" element={<JobListingsPage />} />
              <Route path="/jobs/organization/:slug" element={<OrgJobsListingPage />} />
              <Route path="/jobs/career-profile/:slug" element={<OrgCareersProfile />} />
              <Route path="/job/:slug" element={<JobPage />} />
              <Route path="/apply/confirm-profile" element={<EditProfileFormAfterApplying />} />
              <Route path="/admin_ab_2534_list" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <SideNavBar/>
                    <div style={{width:'100%'}}>
                      <Ab2534List/>
                    </div>
                  </Flex>
                </div>
              } />
              <Route path="/admin_school_jobs" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <SideNavBar/>
                    <div style={{width:'100%'}}>
                      <SchoolDashboardJobs/>
                    </div>
                  </Flex>
                </div>
              } />

              <Route path="/admin_candidate_pipeline" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <SideNavBar/>
                    <div style={{width:'100%'}}>
                      <CandidatePipeline/>
                    </div>
                  </Flex>
                </div>
              } />

              <Route path="/admin_team_settings" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <SideNavBar/>
                    <div style={{width:'100%'}}>
                      <TeamSettings/>
                    </div>
                  </Flex>
                </div>
              } />

            <Route path="/admin_reports" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <SideNavBar/>
                    <div style={{width:'100%'}}>
                      <AdminReports/>
                    </div>
                  </Flex>
                </div>
              } />

              <Route path="/admin_school_applications" element={
                <div>
                  <Flex>
                    <SideNavBar/>
                    <SchoolDashboardApplications/>
                  </Flex>
                </div>
              } />
              <Route path="/admin_application/:slug" element={
                <div>
                  <Flex>
                    <SideNavBar/>
                    <StandAloneApplication/>
                  </Flex>
                </div>
              } />
              <Route path="/" element={
                <div style={{height:'100vh'}}>
                  <Outivities/>
                  {/* <PublicJobsCCSA/> */}
                </div>
              } />

            <Route path="/source" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <SideNavBar202407/>
                    <div style={{width:'100%'}}>
                      <Source/>
                    </div>
                  </Flex>
                </div>
              } />

            <Route path="/admin_talent_collective" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <SideNavBar/>
                    <div style={{width:'100%'}}>
                      <AdminTalentCollective/>
                    </div>
                  </Flex>
                </div>
              } />

          <Route path="/jobs" element={
                <div style={{height:'100vh'}}>
                  <Jobs/>
                  {/* <PublicJobsCCSA/> */}
                </div>
              } />


             <Route path="/talent_collective_invitation" element={
                <div style={{height:'100vh'}}>
                  <TalentNetworkReferralPageAdmin/>
                  {/* <PublicJobsCCSA/> */}
                </div>
              } />

              <Route path="/talent_collective_set_password" element={
                <div style={{maxHeight:'100vh', overflow:'hidden'}}>
                  <TalentCollectiveSetPassord/>
                  {/* <PublicJobsCCSA/> */}
                </div>
              } />




                <Route path="/ccsa_staff/schools" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <CCSASideNavBar/>
                    <div style={{width:'100%'}}>
                      <Schools/>
                    </div>
                  </Flex>
                </div>
              } />
                <Route path="/ccsa_staff/community" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <CCSASideNavBar/>
                    <div style={{width:'100%'}}>
                      <Community/>
                    </div>
                  </Flex>
                </div>
              } />
               <Route path="/ccsa" element={
                <div>
                  <Flex style={{height:'60vh'}}>
                    <CCSATopNav/>
                    <div style={{width:'100%'}}>
                  <PublicJobsCCSA/>
                  </div>
                  </Flex>
                </div>
              } />
            </Routes>
          </div>
        </Router>
      </ErrorBoundary>
    </SchoolProvider>
  );
}

export default App;
