import React, { useState, useEffect } from 'react';
import { Box, Img, Flex,  Skeleton, Checkbox, ModalBody, ModalFooter,
    SkeletonCircle, Link, Spinner, Alert,
    SkeletonText, Stack, Grid,Card,Badge, GridItem, Text, Button, Image, Heading, Tag, Avatar, Divider, ChakraProvider, Textarea, ModalHeader, useDisclosure, useToast } from '@chakra-ui/react';

import { FiFolder, FiSlash, FiUpload, FiAlertTriangle } from 'react-icons/fi';
import { FaLinkedin, FaEmail } from 'react-icons/fa';
import { FiMail, FiPhoneCall, FiMapPin, FiInfo } from "react-icons/fi";
import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";
import { format, parseISO } from 'date-fns';
import { Input, Button as AntButton } from 'antd';
import ImageUploading from 'react-images-uploading';
import { BiNoEntry } from 'react-icons/bi';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles


function EditProfileForm({job, onClose}) {
  const navigate = useNavigate(); // For navigation after login
  const toast = useToast();

  const [submittingText, setSubmittingText] = useState('');

 

  //Login code//
  const loginUser = async (email, password) => {
    const registrationData = {
      email: email,
      password: password,
    };
  
    try {
        const loginResponse = await fetch('https://www.pursueli.com/api/login/', {
            method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });
  
      if (loginResponse.ok) {
        const data = await loginResponse.json();
        console.log(data);
        localStorage.setItem('authToken', data.token); // Use localStorage for web
        localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
        localStorage.setItem('authenticated', 'True'); // Use localStorage for web
        setAuthenticated(true); // Update the authentication state
        console.log("user logged in")
        return
        // navigate('/upload_resume/'); // Navigate to home after state update and successful login
      } else {
        const errorData = await loginResponse.json();
        let errorMessage = '';
        if (errorData.email) {
          errorMessage += `Email Error: ${errorData.email[0]}`;
        }
        if (errorData.password) {
          if (errorMessage) errorMessage += '\n';
          errorMessage += `Password Error: ${errorData.password[0]}`;
        }
  
        if (!errorMessage) {
          errorMessage = 'Invalid credentials. Please try again.';
        }
        }
    } catch (error) {
      console.log('An error occurred. Please try again later.');
    }
  };
  /////////////
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleShowPassword = () => setShowPassword(!showPassword);
  const fontSize = showPassword ? '16px' : '16px';
  const [applying, setApplying] = useState(false);

  const [resumeSource, setResumeSource] = useState('upload');
  const [credentialSource, setCredentialSource] = useState('upload');

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    resume:'',
  });


  const [showInfo, setShowInfo] = useState(false);
  const [joinTalentCollective, setJoinTalentCollective] = useState(true); // Defaulting to false

  // Function to toggle the display of the info box
  const toggleInfoBox = () => setShowInfo(!showInfo);

/////////////
    const [displayCredential, setDisplayCredential] = useState(null); 
    const [copyOfCredential, setCopyOfCredential] = React.useState([]);
    const handleCredentialsChange = (updatedCredentials) => {
        setCopyOfCredential(updatedCredentials);
    };

    const onCopyOfCredentialChange = (imageList, addUpdateIndex) => {
      // Update the resume display based on newly uploaded file
      if (imageList.length > 0) {
        setCopyOfCredential(imageList)
        setDisplayCredential(imageList[0]);
        setCredentialSource('upload')
      } else {
        // If no file is uploaded, revert to showing the resume URL if available
        setDisplayCredential(displayCredential ? { data_url: displayCredential } : null);
        setCredentialSource('upload')
        setCopyOfCredential(imageList)
      }
    };
  

    const onCredentialChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setCopyOfCredential(imageList);
        handleCredentialsChange(imageList); // Call the callback function with the updated images
    };

/////////////
    const [coverLetter, setCoverLetter] = React.useState([]);
    const handleCoverLetterChange = (updatedCoverLetter) => {
        setCoverLetter(updatedCoverLetter);
    };
    const onCoverLetterChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setCoverLetter(imageList);
        handleCoverLetterChange(imageList); // Call the callback function with the updated images
    };

/////////////
    const [displayResume, setDisplayResume] = useState(null);
    const [resume, setResume] = React.useState([]);
    const handleResumeChange = (updatedResume) => {
        setResume(updatedResume);
    };
    const onResumeChange = (imageList, addUpdateIndex) => {
      const isAllPDFs = imageList.every(image => image.file.type === 'application/pdf');
      if (isAllPDFs) {
        // Update state with new image list if all files are PDFs
        setDisplayResume(imageList.length > 0 ? imageList[0] : null);
      } else {
        alert("Please upload a PDF copy of your resume.");
        // Optionally clear the previously selected file
        return
      }

      // Update the resume display based on newly uploaded file
      if (imageList.length > 0) {
        setResume(imageList)
        setDisplayResume(imageList[0]);
        setResumeSource('upload')
      } else {
        // If no file is uploaded, revert to showing the resume URL if available
        setDisplayResume(displayResume ? { data_url: displayResume } : null);
        setResumeSource('upload')
        setResume(imageList)

      }
    };
  


  
    // const [images, setImages] = React.useState([]);

    const handleImagesChange = (updatedImages) => {
      setImages(updatedImages);
  };
  
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    handleImagesChange(imageList); // Call the callback function with the updated images
};


  const { TextArea } = Input;


  const [images, setImages] = React.useState([]);

 
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error("Authorization token not found");
      // Optionally, clear any state or perform additional actions here
      return; // Early return if no authToken
    }

    fetch("https://www.pursueli.com/api/my_profile/", {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }
    })          
    .then((response) => response.json())
    .then((data) => {      
      console.log("User data:", data.user);
      
      // Assuming you want to remove resume_url from userData before setting it
      const { resume, ...restOfUserData } = data.user; // Destructuring to separate resume_url from the rest of userData
      
      setUserData(restOfUserData); // Update state with fetched user data excluding resume_url

      // Now handle resume_url separately
      if (data.resume_url && data.resume_url !== "BiNoEntry") { // Adjust the condition based on your data structure and what indicates a missing resume
        setDisplayResume(data.resume_url);
        setResumeSource('authSaved')
      } else {
        // Handle case where resume_url is "BiNoEntry" or not present
        setDisplayResume(null);
      }

      if (data.credential_url && data.credential_url !== "BiNoEntry") { // Adjust the condition based on your data structure and what indicates a missing resume
        setDisplayCredential(data.credential_url);
        setCredentialSource('authSaved')
      } else {
        // Handle case where resume_url is "BiNoEntry" or not present
        setDisplayCredential(null);
      }


      
    })
    .catch((error) => console.error("Error fetching user data:", error));
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'phone') {
      const formattedPhone = formatPhoneNumber(value);
      setUserData(prevState => ({
        ...prevState,
        [name]: formattedPhone,
      }));
    } else {
      setUserData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  
  function formatPhoneNumber(value) {
    // Remove all non-digits
    let numbers = value.replace(/\D/g, '');
    // Capture groups of digits for formatting
    let match = numbers.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    
    if (match) {
      // Reconstruct the phone number with desired formatting
      let formattedNumber = `(${match[1]}`;
      if (match[2]) {
        formattedNumber += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedNumber += `-${match[3]}`;
      }
      return formattedNumber;
    }
    return numbers; // Return the cleaned numbers string if no matches found
  }

  const [authenticated, setAuthenticated] = React.useState('');

  const handleSubmit = async () => {

    const searchParams = new URLSearchParams(window.location.search);
      const sourceParam = searchParams.get('source');
    
    const authToken = localStorage.getItem('authToken');
    const formData = new FormData();

    if (joinTalentCollective === true && password !== password2) {
      alert("Passwords do not match");
      return;
    }
    setApplying(true)
    setSubmittingText("Submitting your application. This may take up to 30 seconds ...")
    formData.append('first_name', userData.first_name);
    formData.append('last_name', userData.last_name);
    formData.append('email', userData.email);
    formData.append('phone', userData.phone);
    formData.append('address', userData.address);
    formData.append('authUserResume', displayResume);
    formData.append('resumeSource', resumeSource);
    formData.append('jobId', job.id);
    formData.append('joinTalentCollective', joinTalentCollective);
    formData.append('authToken', authToken);
    formData.append('password', password);
    formData.append('password2', password2);
    formData.append('cover_letter', userCoverLetter);
    formData.append('source', sourceParam);

    if (resume.length > 0) {
      formData.append('resume', resume[0].file);
    }
    if (copyOfCredential.length > 0) {
      formData.append('copyOfCredential', copyOfCredential[0].file);
    }
    if (coverLetter.length > 0) {
      formData.append('coverLetter', coverLetter[0].file);
    }

    try {
      const response = await fetch("https://www.edpursuit.com/api/school_public_route_appl_to_job/", {
        method: 'POST',
        headers: authToken ? { 'Authorization': `Bearer ${authToken}` } : {},
        body: formData,
      });
  
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      const applicationId = data

      // const data = await response.json();

      console.log("Profile update response:", data);
      if (!authToken) {
        //user is not authenticated
      if (joinTalentCollective) {
        
        const registrationData = {
          email: userData.email,
          password: password,
        };
        try {
            const loginResponse = await fetch('https://www.pursueli.com/api/login/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationData),
          });
          if (loginResponse.ok) {
            const data = await loginResponse.json();
            console.log(data);
            localStorage.setItem('authToken', data.token); // Use localStorage for web
            localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
            localStorage.setItem('authenticated', 'True'); // Use localStorage for web
            setAuthenticated(true); // Update the authentication state
            console.log("user logged in")
            setSubmittingText("Creating your talent collective...")
          }
          
        } catch (error) {
          console.error("Failed to process the data:", error);
        }
        const baseUrl = "https://www.pursueli.com/api"; // Replace with your API base URL
        const authToken = localStorage.getItem('authToken');
        const text = data.text; // Update 'text' if necessary
        const applicationId = data.applicationId
        const workExpRequest = fetch(`${baseUrl}/school_public_route_ai_update_work_exp/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text, applicationId }) // Include both text and applicationId in the body
        });
        const eduExpRequest = fetch(`${baseUrl}/school_public_route_ai_update_edu_exp/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text, applicationId }) // Include both text and applicationId in the body
        });
        try {
          // Execute both requests in parallel and wait for both to complete
          const responses = await Promise.all([workExpRequest, eduExpRequest]);
          // Process responses
          for (const response of responses) {
            if (!response.ok) {
              // Handle each error individually
              console.error(`Error: ${response.status}`);
              continue; // Skip to the next response
            }
            const data = await response.json();
            console.log(data); // Process your data
          }
          toast({
            title: 'Application submitted.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          // Navigate after processing both requests, regardless of individual outcomes
          console.log("Both processes completed. Navigating to welcome screen...");
          // navigate('/apply/confirm-profile'); // Navigate to home after state update and successful login
          navigate('/home'); // Navigate to home after state update and successful login

          // navigate('/welcome_new_user_and_confirm_profile'); // Replace with your navigation logic
        } catch (error) {
          console.error("An error occurred while processing the data:", error);
        }
        fetch('https://www.pursueli.com/api/enrich_application/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              // Assuming you store the user's token in localStorage
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify({
              application_id: applicationId,
          }),
      });
        console.log("enrichment complete")
        setApplying(false)
        
        
        } else {
          // User is not authenticated and chose not to join the talent collective
          // apply to job and then enrich application asyncrounosuly
          // show success and allow user to go onto next job
          toast({
            title: 'Application submitted.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setApplying(false)
          onClose()
          console.log("heres the data! ", data)
          const baseUrl = "https://www.pursueli.com/api"; // Replace with your API base URL
          const authToken = localStorage.getItem('authToken');
          const text = data.text; // Update 'text' if necessary
          const applicationId = data.applicationId
          const workExpRequest = fetch(`${baseUrl}/school_public_route_ai_update_work_exp/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text, applicationId }) // Include both text and applicationId in the body
          });
          const eduExpRequest = fetch(`${baseUrl}/school_public_route_ai_update_edu_exp/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text, applicationId }) // Include both text and applicationId in the body
          });

          try {
            const responses = await Promise.all([workExpRequest, eduExpRequest]);
            for (const response of responses) {
              if (!response.ok) {
                // Handle each error individually
                console.error(`Error: ${response.status}`);
                continue; // Skip to the next response
              }
              const data = await response.json();
              console.log(data); // Process your data
            }
            console.log("Both processes completed. Navigating to welcome screen...");  
          } catch (error) {
            console.error("An error occurred while processing the data:", error);
          }
          fetch('https://www.pursueli.com/api/enrich_application_from_job_page/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Assuming you store the user's token in localStorage
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({
                application_id: applicationId,
            }),
        });
          console.log("enrichment complete")

        }
      } else {
        setApplying(false)
        toast({
          title: 'Application submitted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose(); // Close the modal
        console.log("enriching application")
        console.log(applicationId)
        fetch('https://www.pursueli.com/api/enrich_application/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              // Assuming you store the user's token in localStorage
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify({
              application_id: applicationId,
          }),
      });
        console.log("enrichment complete")

        
  
        // User is signed in and applying to a role
        // apply to job and then enrich application asyncrounosuly
        // show success and allow user to go onto next job
        // Minimal processing required
      }
      // Example of processing if AI enrichment is required
      // setLoadingText('Updating your work history ...')
      // const aiResponse = await fetch(`${baseUrl}/ai_endpoint/`, { ... });
      // Check response and proceed accordingly
  
    } catch (error) {
      console.error("Failed to process the data:", error);
    }

  };

  const modules = {
    toolbar: [

        ['bold', 'italic', 'underline', 'link'], // Adding 'link' for hyperlinks
        [{'list': 'ordered'}, {'list': 'bullet'}],
        
        // Ensure headers or any 'header' related options are not included here
    ],
};

const formats = {
formats : [
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link'
]
};
const [userCoverLetter, setUserCoverLetter] = useState('');




  

  
  if (!userData) {
    return      <Grid
    textAlign={'center'}
    templateColumns={{ sm: 'repeat(0, 0fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
    width={'100%'}
  >
    <Box  width={'100%'} minWidth={'100%'} >
    <Grid templateColumns="repeat(2, 1fr)" gap={4} mb="6">
      <GridItem colSpan={1}>
      <Skeleton height='40px' mt="4" noOfLines={2} spacing="4" />
      </GridItem>
      <GridItem colSpan={1}>
      <Skeleton height='40px' mt="4" noOfLines={2} spacing="4" />
      </GridItem>
    </Grid>
    <Grid templateColumns="repeat(2, 1fr)" gap={4} mb="6">
      <GridItem colSpan={1}>
      <Skeleton height='40px' my="4" noOfLines={2} spacing="10" />
      </GridItem>
      <GridItem colSpan={1}>
      <Skeleton height='40px' mt="4" noOfLines={2} spacing="10" />
      </GridItem>
    </Grid>
    <Grid templateColumns="repeat(1, 1fr)" gap={4} mb="6">
      <GridItem colSpan={1}>
      <Skeleton height='40px'noOfLines={2} spacing="10" />
      </GridItem>
    </Grid>
  </Box>
  </Grid>
  ;
  }
  

  return (
    <Box align="center"   justify="center">
       <style>

{
  `.custom-quill .ql-container {
border: 1px solid #E2E8F0; /* Chakra's gray.300 border color */
border-radius: 0 0 0.375rem 0.375rem; /* Chakra's md border radius */
}

.custom-quill .ql-editor {
min-height: 120px; /* Set to desired minimum height */
font-size: 1rem; /* Chakra's default font size */
}
.custom-quill .ql-container:focus-within {
border-color: #3182CE; /* Chakra's blue.500 */
box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
.ql-toolbar {
border-color: #3182CE; /* Chakra's blue.500 */
box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
}
}


.custom-quill .ql-toolbar {
border-color: #E2E8F0; /* Chakra's gray.300 border color */
border-radius: 0.375rem 0.375rem 0 0; /* Rounded top corners */
}`


}
</style>

      <Box p={3}  rounded="md">
        <Box  textAlign={'start'}>
        <ModalHeader pt={0} mt={0} fontWeight="bold" pl={0}>Personal Details</ModalHeader>
        <Grid
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text color={'grey'}>First name</Text>
            <Box alignSelf={'flex-end'}>
            <Input  required name="first_name" size='large' value={userData.first_name} placeholder="Jane" onChange={handleInputChange} />
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text color={'grey'}>Last name</Text>
            <Box alignSelf={'flex-end'}>
            <Input required name="last_name" size='large' value={userData.last_name} placeholder="Doe" onChange={handleInputChange} />
            </Box>
        </Box>
      </GridItem>
    </Grid>
        <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text color={'grey'}>Email</Text>
            <Box alignSelf={'flex-end'}>
            <Input required name="email" size='large' value={userData.email} placeholder="janedoe@email.com" onChange={handleInputChange} />
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text color={'grey'}>Phone</Text>
            <Box alignSelf={'flex-end'}>
            <Input required name="phone" size='large' value={userData.phone} placeholder="(408)510-1111" onChange={handleInputChange} />
            </Box>
        </Box>
      </GridItem>
    </Grid>
    
      </Box>
      </Box>

      {job.ask_for_letter_of_interest && (
        <Box p={3} rounded="md">
          <Box textAlign="start">
            <ModalHeader pt={0} mt={0} fontWeight="bold" pl={0}>Cover Letter
            {job.letter_of_interest_required && (
                          <span style={{ color: 'red', marginLeft: '5px', fontSize:'14px' }}>*</span>
                        )}
                                    <Text fontSize={'15px'} fontWeight={'normal'} color={'grey'}>Type your cover letter, also known as a "letter of interest" below.</Text>

            </ModalHeader>
            <Box className="custom-quill"  p={0} maxW="full" mx="auto">
                  <ReactQuill
                  // readOnly={loadingAI} // Make the editor read-only
                  name="requirements" value={userCoverLetter} onChange={setUserCoverLetter}    
                  formats={formats}            
                  modules={modules}/>
                </Box>
          </Box>
        </Box>
      )}









      <Box p={3}  rounded="md" mb={3}>
        <Box  textAlign={'start'}>
        <ModalHeader pt={0} mt={0} fontWeight="bold" pl={0}>Attachments</ModalHeader>
        <Grid
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}   pl={0}
    >
      <GridItem  ml={0} w="100%">
      <ImageUploading  
 multiple={false} value={displayResume ? [displayResume] : []} onChange={onResumeChange} maxNumber={1}  dataURLKey="data_url" allowNonImageType={true}  
      >
        {({  imageList, onImageUpload, onImageUpdate, onImageRemove}) => (
          <div  >
            <div >
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                 <Box color={'gray'} pb={3}  fontWeight={'bold'}>Resume</Box>
                            {
              image.file ? (
                image.file.type.includes("pdf") ?
                  <embed src={image['data_url']} type="application/pdf" style={{width:'100%',height:'50vh', border:'none', boxShadow:'none', background:'none' }} />
                  :
                  <img src={image['data_url']} alt="" style={{width:'100%', height:'auto', border:'none', boxShadow:'none', background:'none' }} />
              ) : (
                // Assuming all external URLs you're working with are PDFs for simplicity; adjust as needed
                <embed src={displayResume} type="application/pdf" style={{width:'100%', height:'50vh', border:'none', boxShadow:'none', background:'none' }}/>
              )
              
            }    
              </div>
            ))}
            </div>

            <div   style={{ textAlign:'center'}}>
                <div>
                    <div style={{display:'flex', alignItems:'center'}}>
                    {imageList.length === 0 ? (
                    <>
                    <Button leftIcon={<FiUpload />} variant={'outline'}  onClick={onImageUpload}>
                        Upload resume     
                        {job.resume_required && (
                          <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                        )}


                    </Button>
                    </>
                ) : (
                    <>
                <Box mt={3}>
                    {imageList.length > 0 && (
                                <Button  colorScheme='red' variant='ghost' size='md' left={0} justify={'flex-end'} onClick={(event) => {
                                    event.stopPropagation(); // Prevent event from bubbling up
                                    onImageRemove(0); // Remove the image
                                    setDisplayResume('');
                                }}> <FiSlash style={{marginRight:'10px'}}  w={4} h={4} />
                                Remove
                                </Button>
                    )}
                </Box>
                    </>
                )}
                </div>
                </div>
                </div>
          </div>
        )}
      </ImageUploading>

      </GridItem>

      {job.ask_for_credential && (
      <GridItem w="100%" style={{minWidth:'100%', maxWidth:'100%'}}>


<ImageUploading multiple={false} value={displayCredential ? [displayCredential] : []} onChange={onCopyOfCredentialChange} maxNumber={1}  dataURLKey="data_url" allowNonImageType={true}
      >
        {({  imageList, onImageUpload, onImageUpdate, onImageRemove}) => (
          <div  >
            <div >
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                 <Box color={'gray'} pb={3}  fontWeight={'bold'}>Copy of credential</Box>
                            {
              image.file ? (
                image.file.type.includes("pdf") ?
                  <embed src={image['data_url']} type="application/pdf" style={{width:'100%',height:'50vh', border:'none', boxShadow:'none', background:'none' }} />
                  :
                  <img src={image['data_url']} alt="" style={{width:'100%', height:'auto', border:'none', boxShadow:'none', background:'none' }} />
              ) : (
                // Assuming all external URLs you're working with are PDFs for simplicity; adjust as needed
                <embed src={displayCredential} type="application/pdf" style={{width:'100%', height:'50vh', border:'none', boxShadow:'none', background:'none' }}/>
              )
              
            }    
              </div>
            ))}
            </div>






     









            <div   style={{ textAlign:'center', minWidth:'100%'}} >
                <div style={{ }}>
                    <div style={{display:'flex', alignItems:'center'}}>
                    {imageList.length === 0 ? (
                    <>
                    <Button leftIcon={<FiUpload />} variant={'outline'} onClick={onImageUpload}>
                        Upload credential
                        {job.copy_of_credential_required && (
                          <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                        )}
                    </Button>
                    </>
                ) : (
                    <>
                <Box mt={3}>
                    {imageList.length > 0 && (
                                <Button  colorScheme='red' variant='ghost' size='md' left={0} justify={'flex-end'} onClick={(event) => {
                                    event.stopPropagation(); // Prevent event from bubbling up
                                    onImageRemove(0); // Remove the image
                                    setDisplayCredential('');
                                }}> <FiSlash style={{marginRight:'10px'}}  w={4} h={4} />
                                Remove
                                </Button>
                    )}
                </Box>
                    </>
                )}
                </div>
                </div>
                </div>
          </div>
        )}
      </ImageUploading>
      </GridItem>
      )}
      {/* <GridItem w="100%">
      <ImageUploading multiple={false} value={coverLetter} onChange={onCoverLetterChange} maxNumber={1}  dataURLKey="data_url" allowNonImageType={true}
      >
        {({  imageList, onImageUpload, onImageUpdate, onImageRemove}) => (
          <div  >
            <div >
            {imageList.map((image, index) => (
              <div key={index} className="image-item" style={{ borderColor:'transparent' }}>
                 <Box color={'gray'} pb={3}  fontWeight={'bold'}>Cover letter</Box>
            {
      image.file.type.includes("pdf") ?
      // For PDF, use an <embed> tag or a PDF viewer component
      <embed src={image['data_url']} type="application/pdf"  style={{width:'100%', backgroundColor:'transparent', height:'auto', borderColor:'transparent' }} />
      :
      // For images, keep using the <img> tag
      <img src={image['data_url']} alt="" style={{height:'90%', borderRadius:'10px'}} />
    }             
              </div>
            ))}
            </div>

            <div   style={{ textAlign:'center'}}>
                <div>
                    <div style={{display:'flex', alignItems:'center'}}>
                    {imageList.length === 0 ? (
                    <>
                    <Button leftIcon={<FiFolder />} onClick={onImageUpload}>
                        Upload letter of interest
                        {job.letter_of_interest_required && (
                          <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                        )}
                    </Button>
                    </>
                ) : (
                    <>
                <Box mt={3}>
                    {imageList.length > 0 && (
                                <Button  colorScheme='red' variant='ghost' size='md' left={0} justify={'flex-end'} onClick={(event) => {
                                    event.stopPropagation(); // Prevent event from bubbling up
                                    onImageRemove(0); // Remove the image
                                }}> <FiSlash style={{marginRight:'10px'}}  w={4} h={4} />
                                Remove
                                </Button>
                    )}
                </Box>
                    </>
                )}
                </div>
                </div>
                </div>
          </div>
        )}
      </ImageUploading>
      </GridItem>
       */}
    </Grid>
    
      </Box>
      </Box>

       <Divider/>

       {!localStorage.getItem('authToken') && (
  <Box display="flex" alignItems="center" pt={5} textAlign={'center'} justifyContent={'center'}>
    <Checkbox id="checkbox-id" defaultChecked 
      isChecked={joinTalentCollective}
      onChange={(e) => setJoinTalentCollective(e.target.checked)}
    />
    <Text ml={2} htmlFor="checkbox-id">Join the edpursuit talent collective</Text>
    <Button variant={'none'} background={'transparent'} ml={3} p={1} onClick={toggleInfoBox}>
      <FiInfo/>
    </Button>
  </Box>
)}
     {/* Conditional rendering based on showInfo state */}
     {showInfo && (
        <ModalBody mx={5} mt={2} textAlign={'center'} borderWidth="1px" borderRadius="lg">
            Joining the edpursuit talent collective saves your profile for easier applications across the edpursuit network. Additional benefits include job matching, insights into schools that are hiring, and more.
        </ModalBody>
      )}


<Alert status="warning" style={{borderRadius:6, textAlign:'start', alignItems:'start'}}>
  
  <Text color='#92310a' fontSize={'xs'} style={{ gap:12, justifyContent:'center', display:'flex', alignItems:'start', justifyContent: 'center'}}>
  <span>
  <FiAlertTriangle color='#92310a' fontSize={'16px'}/>
  </span>
  Effective January 1, 2025, California Education Code section 44939.5 (amended by AB 2534) requires all applicants for certificated positions to disclose a complete list of all previous employment with school districts, county offices of education, charter schools, and state special schools, regardless of duration. This includes part-time, substitute, and full-time roles. Failure to disclose prior educational employment may result in disqualification of your application or, if employed, termination for dishonesty in the hiring process.
  </Text>
</Alert>
          <ModalFooter >
          <Flex
    direction={{ base: 'column', md: 'row' }} // Stack vertically on small screens, horizontally on medium and larger screens
    align="center" // Align items vertically in the center
    justify="center" // Center items horizontally
    gap={4} // Space between each item
    mx="auto" // Center the Flex container itself
    width="full" // Take full width of its container
  >

      {joinTalentCollective && !localStorage.getItem('authToken') && (
      <>
        <Button variant={'ghost'} size="sm" onClick={handleToggleShowPassword}>
            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
          </Button>
        <Input
          pl={0}
          ml={0}
          type={showPassword ? 'text' : 'password'}
          name="password"
          width={{ base: '100%', md: '30%' }}
          size='large'
          style={{ fontSize }}
          placeholder="Create password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type={showPassword ? 'text' : 'password'}
          name="password2"
          width={{ base: '100%', md: '30%' }}
          size='large'
          style={{ fontSize }}
          placeholder="Confirm password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </>
    )}
 {
  !applying
    ? <Button onClick={handleSubmit} width={{ base: '100%', md: '40%' }} colorScheme='blue'>Submit</Button>
    : (
        <Flex alignItems={'center'}>
          <Spinner/>
          <Box>{submittingText}</Box>
        </Flex>
      )
}





  </Flex>
          </ModalFooter>
      

    </Box>
  );
}

export default EditProfileForm;
