import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  VStack,
  Img,
  Text,
  Select,
  SkeletonCircle,
  Skeleton,
  useColorMode,
  useColorModeValue,
  Button,
  Card,
} from '@chakra-ui/react';
import {
  FiSettings, FiFileText, FiBriefcase, FiFilter, FiBarChart2,
  FiStar,
  FiUserCheck,
} from 'react-icons/fi';

import { useSchool } from '../context/admin_selected_school';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import WebFont from 'webfontloader';

function SideNavBar() {
  const [schoolAdmin, setSchoolAdmin] = useState(null);
  const handleSchoolChange = (event) => {
    changeSchool(event.target.value);
  };
  

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { colorMode } = useColorMode();
  const color = useColorModeValue('gray.700', 'gray.100');
  const logoTextColor = useColorModeValue('gray.800', 'white'); // Moved here

  const [schoolLogo, setSchoolLogo] = useState('');
  const [schoolOrgName, setSchoolOrgName] = useState('');
  const { school, changeSchool } = useSchool();
  const navigate = useNavigate();

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kurale']
      }
    });
  }, []);

  useEffect(() => {
    fetch(`https://www.pursueli.com/api/get_school_admin/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      if (!localStorage.getItem('authToken')) {
        localStorage.setItem('redirectPath', location.pathname);
      }
      if (!data.admin_user) {
        navigate('/recruiter_login');
      }
      setSchoolAdmin(data.admin_user);
      setSchoolLogo(data.school_logo);
      setSchoolOrgName(data.school_org_name);
      setLoading(false);
      if (!school && data.admin_user.approved_schools.length > 0) {
        changeSchool(data.admin_user.approved_schools[0].school_name);
      }
    })
    .catch(error => console.error("Error fetching jobs:", error));
  }, [school, changeSchool, location.pathname, navigate]);

  return (


      <Card 
        transition={isCollapsed ? 'width 0.3s ease-out' : 'width 0.3s ease-in'} // Remove transition on collapse
        transformOrigin="left"
        overflow="hidden" // Hide overflowing content during transition
        onMouseEnter={() => setIsCollapsed(false)}
        onMouseLeave={() => setIsCollapsed(true)}
       w={isCollapsed ? '6%' : '275px'}
       // transform={isCollapsed ? 'scaleX(0.7)' : 'scaleX(1)'} // Scale the width
       top={0}
       position="fixed"
        h={'100vh'} 
        borderRadius={6} 
        zIndex={'9'} 
        // position="fixed" 
        boxShadow={'xl'} 
        left="0" 
        // bg={"white"} 
        p={4} 
        color={color}
        // style={{backgroundImage:'linear-gradient(#fff, #f9fafb, #f9fafb, #f9fafb, #f9fafb, #f9fafb)'}}
      >
        <Box 
          style={{position:'absolute', bottom:'30px'}} 
          left={0} 
          w={'100%'}
        >
          
            <Box
              display={'flex'}
              justifyContent={'center'}
              fontFamily={'Kurale, serif'}
              fontSize={'25px'} 
              fontWeight={800}
              color={logoTextColor} // Use the variable here
            >
              <Link 
                color='black' 
                fontFamily={'Kurale, serif'} 
                href="https://www.edpursuiit.com" 
                style={{display:'flex', textDecoration:'none'}}
              >
                <Img
                  height={isCollapsed ? '35px' : '50px'}
                  src={isCollapsed ? 'https://i.ibb.co/w71wMk5/Edpursuit-Logomark-Red.png' : 'https://i.ibb.co/7bSBvCL/Edpursuit-Logo-Dark-Grey-Red.png'}

                />
              </Link>
            </Box>
    
        </Box>

        <VStack spacing={4} align="stretch">
          {loading ? (
            <Card 
            bg={'transparent'} 
            variant='outline' 
            textAlign={'center'} 
            shadow={'none'} 
            mb={10} 
            p={isCollapsed ? 0 : 4}

            mt={4}
          >
              <SkeletonCircle size="14" mx={'auto'}/>
              <Box mx={'auto'}
                           display={isCollapsed ? 'none' : 'block'}
              >
                <Skeleton height="20px" mt={4} width="180px" />
                <Skeleton height="15px" mt={3} width="180px" />
              </Box>
            </Card>
          ) : (
            <Box minHeight={'155px'}>
            <Card 
              bg={'transparent'} 
              variant='outline' 
              textAlign={'center'} 
              shadow={'none'} 
              mb={10} 
              p={isCollapsed ? 1 : 0}

              mt={4}
            >
              <Avatar size={'md'} mx={'auto'} src={schoolLogo} 
              
              w={isCollapsed ? '100%' : '45px'}
              h={isCollapsed ? '100%' : '45px'}


              />
              {!isCollapsed && (
                <Box>
                  <Text 
                    mx={'auto'} 
                    textAlign={'center'} 
                    noOfLines={1} 
                    fontSize={'20px'} 
                    fontWeight="bold"
                  >
                    {schoolOrgName}
                  </Text>
                  <Select 
                    variant='flushed' 
                    textAlign={'center'}  
                    size='sm' 
                    value={school || 'all'}  
                    onChange={handleSchoolChange}
                  >
                    {schoolAdmin && schoolAdmin.approved_schools
                      .sort((a, b) => a.school_name.localeCompare(b.school_name))
                      .map((school, index) => (
                        <option key={index} value={school.school_name}>
                          {school.school_name}
                        </option>
                    ))}
                  </Select>
                </Box>
              )}
            </Card>
            </Box>
          )}
          <VStack align="stretch" spacing={4}  >
            <NavItem icon={FiBriefcase} label="Job Postings" path="/admin_school_jobs" isCollapsed={isCollapsed} mb={0} />
            <NavItem icon={FiFileText} label="Applications" path="/admin_school_applications" isCollapsed={isCollapsed} mb={0}  />
            <NavItem icon={FiFilter} label="Pipeline" path="/admin_candidate_pipeline" isCollapsed={isCollapsed} mb={0}  />
            <NavItem icon={FiBarChart2} label="Metrics" path="/admin_reports" isCollapsed={isCollapsed} mb={0}  />
            <NavItem icon={FiSettings} label="Settings" path="/admin_team_settings" isCollapsed={isCollapsed} mb={0}  />
            <hr style={{ marginTop: '20px' }} />

            <NavItem
                  icon={FiUserCheck}
                  label="AB 2534 Checks (demo)"
                  path="/admin_ab_2534_list"
                  isCollapsed={isCollapsed}
                  mb={0}
                />

                <NavItem
                  icon={FiStar}
                  label="Talent Collective (demo)"
                  path="/admin_talent_collective"
                  isCollapsed={isCollapsed}
                  mb={0}
                />
            {/* {schoolOrgName === "ACME Middle School" && (
              <>
                <hr style={{ marginTop: '20px' }} />
                <NavItem
                  icon={FiStar}
                  label="Talent Collective"
                  path="/admin_talent_collective"
                  isCollapsed={isCollapsed}
                  mb={0}
                />
              </>
              )} */}
          </VStack>
        </VStack>
      </Card>
  );
}

function NavItem({ icon, label, path, isCollapsed, mb }) {
  let navigate = useNavigate();
  let location = useLocation();  
  // Determine if this nav item is active
  const isActive = location.pathname === path;
  return (
<Button
  textAlign={isCollapsed ? 'center' : 'start'}
  justifyContent={isCollapsed ? 'center' : 'flex-start'}
  variant='ghost'
  onClick={() => navigate(path)}
  w="100%"
  color={isActive ? 'blue.500' : 'gray.700'}  // Active item color
mb={mb}
  
>
  <Box mr={isCollapsed ? 0 : 2} display="flex" alignItems="center">
    {React.createElement(icon, { size: isCollapsed ? "18px" : "" }) }
  </Box>
  {!isCollapsed && (
    <Box as="span">
      {label}
    </Box>
  )}
</Button>


  );
}

export default SideNavBar;
